import Vue from "vue";
import VueI18n from "vue-i18n"; // 引入i18n模块
import enLocale from "./en"; // 本地英文包
import zhLocale from "./zh"; // 本地中文包
import twLocale from "./tw"; // 本地繁体包
import { getUrlParam } from "../utils/helper";

Vue.use(VueI18n); // vue使用i18n模块

//得到默认使用的语言  en英文  zht繁体 zh简中
const getDefaultLang = () => {
  let { lang } = getUrlParam();
  // console.log("获取url参数的lang====>", lang);
  if (lang == "zh") {
    return "zh";
  } else if (lang == "zht") {
    return "tw";
  } else if (lang == "en") {
    return "en";
  } else {
    return "zh";
  }
};

// 引入本地
const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  },
  tw: {
    ...twLocale,
  },
};

// 创建国际化实例
const i18n = new VueI18n({
  legacy: false, //避免报错
  locale: getDefaultLang(),
  messages,
});

export default i18n;
