export default {
  basic: {
    // 个人中心相关
    accountStatus: "帳號狀態",
    touristLogin: " 遊客登入",
    emailLogin: "郵箱登入",
    phoneLogin: "手機登入",
    appleLogin: "Apple登入",
    huaWeiLogin:'HuaWei登入',
    facebookLogin: "Facebook登入",
    googleLogin: "Google登入",
    bind: "已綁定",
    noBind: "未綁定",
    apple: "Apple",
    facebook: "Facebook",
    google: "Google",
    email: "郵箱",
    phoneNumber: "手機號",
    changePassword: "修改密碼",
    closeAccount: "帳號註銷",
    switchAccount: "切換帳號",
    customerService:'聯繫客服',

    // 支付页面相关
    payPage: "儲值",
    commodity: "商品",
    chooseArea: "選擇地區",
    choosePayWays: "請選擇付款方式",
    moreWays: "更多付款方式",
    payBtn: "確認",
    closePayBtn: "關閉",
    paySuccess: "付款成功",
    payError: "付款失敗，請重試",
    pointCardTitle:"請選擇支付金額",
    payTitle: "選擇檔位完成充值",
		orderWait:'您的訂單正在處理中，請耐心等候',

    // 弹窗相关
    tipTitle: "提示",
    confirmBtn: "確定",
    cancelBtn: "取消",
    closeTipContent:
      "註銷後帳號將無法登入，無法找回。且會刪除該帳號下所有資料，是否確認注销？",
    loading: '加載中',
  },
};
