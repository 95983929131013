import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    // redirect: "/userCenter",
  },
  {
    path: "/userCenter",
    name: "userCenter",
    component: () => import("@/views/userCenter/index.vue"),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("@/views/pay/index.vue"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/pay_handle",
    component: () => import("@/views/pay/pay_handle.vue"),
    meta: {
      title: "支付成功",
    },
  },
  {
    path: "/payResult",
    name: "payResult",
    component: () => import("@/views/pay/payResult.vue"),
    meta: {
      title: "支付结果",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // mode: "hash",
  routes,
});

// 动态修改浏览器title
router.beforeEach((to, from, next) => {
  // console.log("全局守卫", to, from);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
