import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// import HwUi from "./hwui.js";
// import "amfe-flexible";
import { Overlay, Button, Field, CellGroup, Toast,NavBar, Popup, Col, Row,Image as VanImage,SubmitBar  } from "vant";
import i18n from "./language/index";
// import VConsole from "vconsole";

class HWGameSDK {
  constructor() {
    // this._loginCallback = null;
    this.createSdkApp();

    // Vue.use(HwUi);
    Vue.use(i18n);
    Vue.use(Button);
    Vue.use(Overlay);
    Vue.use(Popup);
    Vue.use(Field);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(VanImage);
    Vue.use(SubmitBar );
    Vue.use(CellGroup);
    Vue.use(Toast);
    Vue.use(router);
    Vue.use(NavBar);

    this._vue = new Vue({
      el: "#_sdk_app",
      store,
      i18n,
      router,
      render: (h) => h(App),
    });

    // 调试器
    // new VConsole();
 
    Vue.prototype.$BCGameSDK = this;
  }

  createSdkApp() {
    if (!document.getElementById("_sdk_app")) {
      var appObj = document.createElement("div");
      appObj.id = "_sdk_app";
      document.body.insertBefore(appObj, document.body.lastChild);
    }
  }

}

window.HWGameSDK = new HWGameSDK();
