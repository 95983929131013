export default {
  basic: {
    // 个人中心相关
    accountStatus: "账号状态",
    touristLogin: " 游客登陆",
    emailLogin: "邮箱登陆",
    phoneLogin: "手机登陆",
    appleLogin: "Apple登陆",
    huaWeiLogin:'HuaWei登录',
    facebookLogin: "Facebook登陆",
    googleLogin: "Google登陆",
    bind: "已绑定",
    noBind: "未绑定",
    apple: "Apple",
    facebook: "Facebook",
    google: "Google",
    email: "邮箱",
    phoneNumber: "手机号",
    changePassword: "修改密码",
    closeAccount: "账号注销",
    switchAccount: "切换账号",
    customerService:'联系客服',
  

    // 支付页面相关
    payPage: "支付",
    commodity: "商品",
    chooseArea: "选择地区",
    choosePayWays: "请选择支付方式",
    moreWays: "更多支付方式",
    payBtn: "支付",
    closePayBtn: "关闭",
    paySuccess: "支付成功",
    payError: "支付失败，请重试",
    pointCardTitle:"请选择支付金额",
    payTitle: "选择档位完成充值",
    orderWait:'您的订单正在处理中，请耐心等待',

    // 弹窗相关
    tipTitle: "提示",
    confirmBtn: "确定",
    cancelBtn: "取消",
    closeTipContent:
      "注销后账号将无法登陆，无法找回。且会删除该账号下所有数据，是否确认注销？",
    loading: '加载中',
  },
};
