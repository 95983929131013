export default {
  basic: {
    // 个人中心相关
    accountStatus: "Account",
    touristLogin: "tourist",
    emailLogin: "Email",
    phoneLogin: "Phone",
    appleLogin: "Apple",
    facebookLogin: "Facebook",
    googleLogin: "Google",
    bind: "Bound",
    noBind: "Unbound",
    apple: "Apple",
    huaWeiLogin:'HuaWei',
    facebook: "Facebook",
    google: "Google",
    email: "Email",
    phoneNumber: "Phone number",
    changePassword: "Change password",
    closeAccount: "Close account",
    switchAccount: "Switch account",
    customerService:'CS',

    // 支付页面相关
    payPage: "Payment",
    commodity: "Purchases",
    chooseArea: "Area",
    choosePayWays: "Please Choose payment",
    moreWays: "More",
    payBtn: "Pay",
    closePayBtn: "Close",
    paySuccess: "Success",
    payError: "Error, please try again",
    pointCardTitle:"Please choose payment",
    payTitle: "Select the tier to complete the recharge",
		orderWait:'Your application is in processing，\nplease wait',

    // 弹窗相关
    tipTitle: "Notice",
    confirmBtn: "OK",
    cancelBtn: "Cancel",
    closeTipContent:
      "Confirm to close account? All data will be deleted and the account will not be able to restore.",
    loading: 'Loading',
  },
};
