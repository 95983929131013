<template>
  <div id="app">
    <router-view />

    <!-- 个人中心页面 -->
    <!-- <van-overlay :show="showUserCenter" @click="showUserCenter = false">
      <div v-if="showUserCenter" class="wrapper" @click.stop>
        <transition name="van-slide-up">
          <UserCenter v-if="showUserCenter" />
        </transition>
      </div>
    </van-overlay> -->

    <!-- 支付页面 -->
    <!-- <van-overlay :show="showPay" @click="showPay = false">
      <div v-if="showPay" class="wrapper" @click.stop>
        <transition name="van-slide-up">
          <pay
            @closePay="closePay"
            :payParams="orderParams"
            :sdkParams="sdkCommonParams"
          ></pay>
        </transition>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
export default {
  name: "app",

  data() {
    return {
      // showUserCenter: true,
      // showPay: false,
      // sdkCommonParams: {},
      // orderParams: {},
    };
  },
  methods: {
    // closePay() {
    //   this.showPay = false;
    // },
  },
};
</script>

<style lang="scss">
@import "./style/reset.scss";
#app {
  background: #f0f2f5;
}
</style>
