/**
 * 判断是否为URL
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}

/**
 * 判断是否小写
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

/**
 * 判断是否为大写
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
    const reg = /^[A-Za-z0-9]+$/
    return reg.test(str)
}

/**
 * 手机号码判定
 * @param {*} phoneNum
 */
export function isPhoneNumber(phoneNum) {
    let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/
    return reg.test(phoneNum)
}

/**
 * 短信认证码判定
 * @param {*} code
 */
export function isPhoneCode(code) {
    let reg = /^\d{4}$/
    return reg.test(code)
}

/**
 * 密码长度判定
 * @param {*} password
 */
export function isPassword(password) {
    return password.length >= 6
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
    if (typeof str === 'string' || str instanceof String) {
        return true
    }
    return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}

/**
 * 数字及数字字符串返回int值，其他返回0
 */
export function toBeNum(str) {
    if (!isNaN(str)) {
        return parseInt(str)
    } else {
        return 0
    }
}

/**
 * 获取随机数
 * @param min
 * @param max
 * @returns {number}
 */
export function getRandom(min, max) {
    let tmp = max - min
    Math.random() * tmp
    let num = Math.random() * tmp + min
    return parseInt(num, 10)
}

/**
 * 在数组里
 * @param {*} search
 * @param {array} array
 * @returns
 */
export function inArray(search, array) {
    for (let i in array) {
        if (array[i] === search) {
            return true
        }
    }
    return false
}

/**
 * 获取客户端系统
 *
 * @returns {string} ''
 */
export function getClientOs() {
    let client = 'pc'
    let ua = window.navigator.userAgent.toLowerCase()
    if (/(iPhone|iPad|iPod|iOS|Macintosh)/i.test(ua)) {
        client = 'ios'
    } else if (/(Android)/i.test(ua)) {
        client = 'android'
    }
    return client
}

/**
 * 判断是否在我们游戏SDK壳包内部打开
 * @returns {boolean} bool
 */
export function isOurApp() {
    let ua = navigator.userAgent.toLowerCase()
    if (/(YILEGAME|BCHYGAME)/i.test(ua)) {
        return true
    }
    return false
}

/**
 * 是否在微信内打开
 *
 * @returns {string} ''
 */
export function isWecaht() {
    let ua = window.navigator.userAgent.toLowerCase()
    return /micromessenger/i.test(ua)
}

/**
 * 判断是否是 Safari 浏览器
 *
 * @returns true
 */
export function isiOSSafari() {
    var userAgent = navigator.userAgent;
    return (
        /(iPad|iPhone|iPod|Mac)/gi.test(userAgent) &&
        /Safari/.test(userAgent) &&
        !/CriOS/.test(userAgent) &&
        !/FxiOS/.test(userAgent) &&
        !/OPiOS/.test(userAgent) &&
        !/mercury/.test(userAgent) &&
        !/UCBrowser/.test(userAgent) &&
        !/Baidu/.test(userAgent) &&
        !/MicroMessenger/.test(userAgent) &&
        !/QQ/i.test(userAgent)
    );
}

/**
 * 向子页面传递信息
 *
 * @param {object} params
 * @param {string} action
 * @param {boolean} isCallSon
 */
export function postMessage(params, action, isCallSon = true) {
    document.getElementById("game-box").contentWindow.postMessage(
        JSON.stringify({
            is_call_son: isCallSon,
            action: action,
            data: params
        }),
        "*"
    )
}

/**
 * 向红包页面传递信息
 *
 * @param {object} params
 * @param {string} action

 */
export function postMessageRedPacket(params, action) {
    document.getElementById("redPacket-box").contentWindow.postMessage(
        JSON.stringify({
            data: params,
            action: action,        
        }),
        "*"
    )
}

/**
 * 判断是否为空
 * @param {mixed} obj
 * @returns
 */
export function isEmpty(obj) {
    if (
        typeof obj === 'undefined' ||
        obj == null ||
        obj === '' ||
        obj === undefined
    ) {
        return true
    } else {
        return false
    }
}

/**
 * 获取网络状态
 * @param {mixed} netWork
 * @returns
 */
export function getNetwork() {
    var netWork = navigator && navigator.connection && navigator.connection.effectiveType || '4g'
    return netWork
}

// 手动添加mate标签
export function addMeta(name, content) {
    const meta = document.createElement('meta');
    meta.content = content;
    meta.name = name;
    const viewport = document.querySelector('meta[name="viewport"]')
    if (viewport) {
        viewport.setAttribute('content', content)
    } else {
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
}

export function isIphonex() {
    if (typeof window !== 'undefined' && window) {
        return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
    }
    return false;
}


/**
 * 随机生成字符串
 * @param {int} length 
 * @returns 
 */
export function generateMixed(length) {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var res = "";
    for (var i = 0; i < length; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
    }
    return res;
}

/**
 * 获取URL参数
 * @param {string} name
 * @returns
 */
export function getUrlParam(key = '', mode, defaultVal = null) {
    let query
    if(mode == 'hash') {
        const urlHash = window.location.hash;
        const routeParts = urlHash.split('?');
        query = routeParts.length > 1 ? routeParts[1] : '';
    }else{
        query = window.location.search.substring(1);
    }
    const field = query.split("&");
    const urlParams = {};
    for (let i = 0; i < field.length; i++) {
      const pair = field[i].split("=");
      urlParams[pair[0]] = pair[1];
    }
  
    if (key != '') {
      if (key in urlParams) {
        return urlParams[key];
      } else {
        return defaultVal;
      }
    }
    // console.log(urlParams,"urlParams");
    return urlParams;
  }


/**
 * 对字符串进行URL解码
 * @param {string} name
 * @returns
 */
export  function UrlDecode(zipStr){ 
    var uzipStr = ''; 
    for (var i = 0; i < zipStr.length; i += 1) {
      var chr = zipStr.charAt(i); 
      if (chr === '+') { 
        uzipStr += ' ';
      } else if (chr === '%') { 
        var asc = zipStr.substring(i + 1, i + 3); 
        if (parseInt('0x' + asc) > 0x7f) {
          uzipStr += decodeURI('%' + asc.toString() + zipStr.substring(i+3, i+9).toString()); 
          i += 8;
        }else{ 
          uzipStr += AsciiToString(parseInt('0x' + asc)); 
          i += 2;
        } 
      }else{ 
        uzipStr += chr; 
      } 
    } 
  
    return uzipStr; 
  } 
   
  function StringToAscii(str){ 
    return str.charCodeAt(0).toString(16); 
  }
  
  function AsciiToString(asccode){ 
    return String.fromCharCode(asccode); 
  }
  